<template>
  <div :id="selectorId" class="swiper-container">
    <div class="swiper-wrapper">
      <slot name="slide-items"></slot>
    </div>
    <slot name="btn-close"></slot>
    <div v-if="!noArrows" class="swiper-button-next"></div>
    <div v-if="!noArrows" class="swiper-button-prev"></div>
  </div>
</template>

<script>
export default {
  props: {
    opts: Object,
    selectorId: String,
    noArrows: Boolean,
    reinitiate: Boolean
  },
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(
      `#${this.selectorId}.swiper-container`,
      this.opts
        ? this.opts
        : {
            lazy: true,
            speed: 400,
            spaceBetween: 0,
            grabCursor: true,
            autoplay: {
              autoplay: true,
              delay: 10000,
              //disableOnInteraction: true,
            },
            /*  effect: "fade", */
            fadeEffect: {
              crossFade: true,
            },
            navigation: {
              nextEl: `#${this.selectorId} .swiper-button-next`,
              prevEl: `#${this.selectorId} .swiper-button-prev`,
            },
          }
    );

    
    document
      .querySelector(`#${this.selectorId}.swiper-container`)
      .addEventListener("mouseenter", this.pauseSwiper);
    document
      .querySelector(`#${this.selectorId}.swiper-container`)
      .addEventListener("mouseleave", this.playSwiper);
      
    this.$emit("create", this.swiper)

    if (this.reinitiate) {
      setTimeout(() => {
        this.swiper.update();
        this.$forceUpdate();
      }, 1500);
    }
  },
  methods: {
    pauseSwiper() {
      this.swiper.autoplay.stop();
    },
    playSwiper() {
      this.swiper.autoplay.run();
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-container,
.swiper-wrapper {
  width: 100%;
  height: 100%;
}
.swiper-container .swiper-slide {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-container:hover .swiper-button-next,
.swiper-container:hover .swiper-button-prev {
  visibility: visible;
  opacity: 1;
}

.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
  //background-color: white;
  outline: none !important;
  background-color: rgba(0, 163, 156, 0.55);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  right: 10px;
  padding: 25px;
  color: #fff !important;
  fill: #fff !important;
  stroke: black !important;
  transition: all ease-in-out 0.35s;
  visibility: hidden;
  opacity: 0;
  &.swiper-button-disabled {
    opacity: 0.3;
  }
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 250;
  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #000;
    color: #fff;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    i {
      font-size: 20px;
    }
  }
}
.swiper-container.small-arrows {
  .swiper-button-next {
    right: 5px;
  }
  .swiper-button-prev {
    left: 5px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-color: #000;
    border: 2px solid #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    width: 17px;
    height: 17px;
    border-radius: 50%;
    padding: 17px;
    @media screen and (max-width: 599px) {
      padding: 16px;
    }
    @media screen and (max-width: 400px) {
      padding: 15px !important;
    }
    &:hover {
      background-color: #000;
    }
    &::after {
      font-size: 13px;
    }
    @media screen and (max-width: 409px) {
      font-size: 12px;
    }
    &.swiper-button-disabled {
      opacity: 0.3;
    }
  }
}

.swiper-container .swiper-button-next:hover,
.swiper-container .swiper-button-prev:hover {
  background-color: rgba(26, 74, 132, 1);
}

.swiper-container .swiper-button-next::after,
.swiper-container .swiper-button-prev::after {
  font-size: 15px;
}
@media screen and (max-width: 599px) {
  .swiper-container .swiper-button-next,
  .swiper-container .swiper-button-prev {
    width: 18px !important;
    height: 18px !important;
    padding: 18px;
  }

  .swiper-container .swiper-button-next::after,
  .swiper-container .swiper-button-prev::after {
    font-size: 13px;
  }
}
@media screen and (max-width: 380px) {
  .swiper-container .swiper-button-next,
  .swiper-container .swiper-button-prev {
    width: 16px !important;
    height: 16px !important;
    padding: 16px;
  }

  .swiper-container .swiper-button-next::after,
  .swiper-container .swiper-button-prev::after {
    font-size: 12px;
  }
}
</style>
