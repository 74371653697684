<template>
  <footer class="lm--footer">
    <v-container class="lm--footer-container">
      <div class="copy readable">
        <div class="left">
          <span>© {{ year }}</span>
          <h1 class="title-app">{{ appName }}</h1>
          
        </div>
        <span class="reserved">{{ copy ? " "+copy : " Todos los derechos reservados" }}.</span>
      </div>
      <p class="lm--author readable">
        {{ made ? made : "Elaborado por" }}
        <a :href="uri ? uri : '#'" target="_blank">Emilima</a>
      </p>
    </v-container>
  </footer>
</template>

<script>
export default {
  props: {
    copy: String,
    made: String,
    uri: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      appName: process.env.VUE_APP_BASE_NAME
    };
  },
};
</script>

<style lang="scss" scoped>
.lm--footer {
  @media screen and (min-width: 600px){
    margin-top: 60px; // en veremos
  }
  margin-top: 0;
  background: $color-primary;
  font-family: $primary-font;
  text-align: center;
  padding-top: 30px;
  overflow: hidden;
  @media screen and (max-width: 400px) {
    padding-top: 5px;
  }
  .lm--footer-container {
    border-top: 1px solid rgba(255, 255, 255, 0.875);
    @media screen and (max-width: 400px) {
      border-top: 1px solid transparent;
    }
    .copy {
      display: flex;
      justify-content: center;

      font-size: 15px;
      color: #fff;
      font-weight: 400;
      margin-top: 6px;
      .left {
        display: flex;
      }
      .reserved {
        margin-left: 5px;
      }
      @media screen and (max-width: 400px) {
        font-size: 13px;
      }
      @media screen and (max-width: 353px) {
        flex-flow: column nowrap;
        align-items: center;
      }
      .title-app {
        margin: 0 0 0 5px;
        font-size: 15px;
        font-weight: 400;
        display: flex;
        @media screen and (max-width: 400px) {
          font-size: 13px;
        }
      }
    }
    .lm--author {
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 0;
      color: #fff;
      margin-top: 6px;
      @media screen and (max-width: 400px) {
        font-size: 13px;
      }
      a {
        color: $color-secondary;
        text-decoration: none;
        font-weight: 700;
      }
    }
  }
}
</style>
