<template>
  <div>
    <!-- <Header :isPrincipalDomain="!isSubDomain" /> -->

    <div class="ox-hidden p-0 w-100" v-if="!isSubDomain && !isPublicView">
      <CoverPage
        :title1="title1"
        :title2="title2"
        :title3="title3"
        :searchHolder="searchHolder"
        :bgImage="bgImage"
        :logo="logoApp"
        :visitalimaIMG="visitalimaIMG"
        :visitaLimaTitle="visitaLimaTitle"
        :visitaLimaDescription="visitaLimaDescription"
        :visitaLimaURL="visitaLimaURL"
        :visitaLimaURLPhrase="visitaLimaURLPhrase"
      />
    </div>

    <v-main class="lm--main principal-main no-padd-top">
      <Searcher :searchHolder="searchHolder" />
      <router-view></router-view>
    </v-main>
    <!-- v-if="!isSubDomain && !isPublicView" -->

    <!-- <v-main class="lm--main subdomain-wrapper no-padd-top" v-else>
      <router-view></router-view>
    </v-main> -->

    <!-- <LoaderPage :active="loader" :zIndex="150" /> -->
    <Footer v-if="!isSubDomain" :copy="copy" :made="made" :uri="uriEmilima" />

    <!-- MODAL  -->
    <ModalSite :active="getActive" v-if="!isSubDomain">
      <template v-slot:modal-content>
        <div class="lm--card-modal-site">
          <div class="top-items">
            <v-container>
              <div class="d-flex justify-content-between">
                <Logo class="big-logo" :source="logoApp" />
                <v-btn
                  class="readable"
                  @click="$store.dispatch('closeModal')"
                  color="primary"
                  fab
                  small
                  :title="closeBtn[lang]"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
            </v-container>
          </div>

          <div v-if="currentPark" class="container-site">
            <div class="top-info">
              <v-btn
                class="readable"
                @click="$store.dispatch('closeModal')"
                fab
                color="primary"
                small
                outlined
                :title="closeBtn[lang]"
              >
                <v-icon>keyboard_arrow_left</v-icon>
              </v-btn>
              <h3 class="title-site readable">{{ currentPark.DESCRIPCION }}</h3>
            </div>
            <div class="body-info">
              <v-row>
                <v-col cols="12" lg="5" md="5" sm="6">
                  <div
                    class="img-site readable"
                    :title="currentPark.DESCRIPCION"
                    :style="
                      `background-image: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 1))`
                    "
                  >
                    <img
                      :src="`${baseURL}/repositorio/${currentPark.URLFOTO}`"
                      :alt="currentPark.DESCRIPCION"
                      style="object-fit: cover; width: 100%; height: 100%"
                    />
                  </div>
                  <div
                    v-if="currentPark.DIRECCION"
                    class="d-flex align-items-center readable"
                  >
                    <i class="fas fa-map-marker-alt mr-3"></i>
                    <small> {{ currentPark.DIRECCION }} </small>
                  </div>
                </v-col>
                <v-col cols="12" lg="7" md="7" sm="6">
                  <div class="content-right">
                    <h4 class="content-title readable">
                      {{ currentPark.FRASE }}
                    </h4>
                    <p class="content-letters readable">
                      <small
                        v-if="
                          currentPark.HISTORIA &&
                            currentPark.HISTORIA.length > 5
                        "
                        v-html="currentPark.HISTORIA"
                      ></small>
                    </p>
                    <v-row>
                      <v-col cols="12" lg="6" md="6" sm="12">
                        <!-- DATOS GENERALES -->
                        <div class="d-flex flex-column">
                          <div
                            v-if="
                              currentPark.TELEFONO1 || currentPark.TELEFONO2
                            "
                            class="d-flex align-items-center left-item readable"
                          >
                            <i class="fas fa-phone"></i>
                            <small>
                              {{
                                currentPark.TELEFONO1 || currentPark.TELEFONO2
                              }}
                            </small>
                          </div>
                          <div
                            v-if="currentPark.MOVIL1 || currentPark.MOVIL2"
                            class="d-flex align-items-center left-item readable"
                          >
                            <i class="fas fa-mobile-alt"></i>
                            <small>
                              {{ currentPark.MOVIL1 || currentPark.MOVIL2 }}
                            </small>
                          </div>
                          <div
                            v-if="currentPark.CORREO1 || currentPark.CORREO2"
                            class="d-flex align-items-center left-item readable"
                          >
                            <i class="fas fa-envelope"></i>
                            <small>
                              {{ currentPark.CORREO1 || currentPark.CORREO2 }}
                            </small>
                          </div>
                        </div>
                        <!-- DATOS PADRE -->
                        <div
                          class="d-block mt-2"
                          v-if="currentPark.PADRE != '0'"
                        >
                          <ul>
                            <li
                              class="mb-2 readable"
                              v-for="(child, idx) in currentPark.childrens"
                              :key="idx + 89585"
                            >
                            <!-- :href="`https://${child.SUBDOMINIO}`" -->
                              <router-link
                                v-if="child.IDESTADODESARROLLO == 2"
                                aria-disabled="true"
                                :title="
                                  `Presiona para comprar entradas en, ${child.DESCRIPCION}`
                                "
                                class="link"
                                target="blank"
                                :to="{ name: 'HomeSale', params: { park_slug: child.SLUG } }"
                              >
                                <span>{{ child.DESCRIPCION }}</span>
                                <i class="fas fa-external-link-alt ml-3"></i>
                              </router-link>
                              <div
                                v-if="child.IDESTADODESARROLLO == 3"
                                class="link maintance d-inline-block mt-0"
                              >
                                <span>{{ child.DESCRIPCION }}</span>
                                -
                                <small>{{ maintanceText }}</small>
                              </div>
                              <div
                                v-if="child.IDESTADODESARROLLO == 4"
                                class="link proximent d-inline-block mt-0"
                              >
                                <span>{{ child.DESCRIPCION }}</span>
                                -
                                <small>{{ commingText }}</small>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="12">
                        <div class="link-section">
                          <div class="logo-site">
                            <img
                              draggable="false"
                              class="img-fluid"
                              :src="
                                `${baseURL}/repositorio/${currentPark.URLFOTOLOGO}`
                              "
                              :alt="`Logo ${currentPark.DESCRIPCION}`"
                            />
                          </div>
                          <router-link
                            v-if="
                              currentPark.PADRE == '0' &&
                                currentPark.IDESTADODESARROLLO == 2
                            "
                            class="link readable"
                            target="_blank"
                            :to="{ name: 'HomeSale', params: { park_slug: currentPark.SLUG } }"
                          >
                            <span>{{ linkPhase }}</span>
                            <i class="fas fa-external-link-alt ml-3"></i>
                          </router-link>
                          <template>
                            <v-chip
                              dark
                              class="mt-2 readable"
                              color="orange"
                              small
                              v-if="
                                currentPark.PADRE == '0' &&
                                  currentPark.IDESTADODESARROLLO == 3
                              "
                              >{{ maintanceText }}</v-chip
                            >
                            <v-chip
                              dark
                              class="mt-2"
                              color="green"
                              small
                              v-if="
                                currentPark.PADRE == '0' &&
                                  currentPark.IDESTADODESARROLLO == 4
                              "
                              >{{ commingText }}</v-chip
                            >
                          </template>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </template>
    </ModalSite>

    <ModalAnounces
      v-if="anounces.length > 0 && !isSubDomain && !isPublicView"
      :dialog="modalAnounces"
      :anounces="anounces"
      :baseURL="baseURL"
    />

    <!-- <LateralMenu :isSubDomain="isSubDomain"></LateralMenu>

    <SnackbarError v-if="isSubDomain"></SnackbarError> -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import Searcher from "@/components/Searcher";
import Footer from "@/components/Footer";
import Logo from "@/components/Logo";
import Home from "@/views/Home";
import CoverPage from "@/components/CoverPage";
import LoaderPage from "@/components/Loader";
import ModalSite from "@/components/modal/ModalSite";
import ModalAnounces from "@/components/modal/ModalAnounces";
import SnackbarError from "@/views/subdomain/components/SnackbarError.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { setColorsApplication } from "@/plugins/colors";
// subdomains
import LateralMenu from "@/views/subdomain/components/LateralMenu.vue";

export default {
  name: "App",
  components: {
    Header,
    Searcher,
    Footer,
    CoverPage,
    LoaderPage,
    ModalSite,
    ModalAnounces,
    Home,
    SnackbarError,
    Logo,
    // subdomains
    LateralMenu,
  },
  data: () => ({
    //
    parkListByParent: [],
    closeBtn: {
      en: "Press to close the park details",
      es: "Presiona para cerrar los detalles del parque",
    },
    publicViewNames: ["RestoreAccount", "ActivateAccount"],
  }),
  watch: {
    modal(val) {
      if (val) {
        this.accTools.restart(this.lang);
      }
    },
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      accTools: "getAccesibilityTool",
      isSubDomain: "getStatusSubdomain",
    }),
    ...mapState({
      baseURL: (state) => state.baseURL,
      logoApp: (state) => state.logoApp,
      bgImage: (state) => state.bgImage,
      loader: (state) => state.loader,
      title1: (state) => state.coverTitle.part1[state.lang],
      title2: (state) => state.coverTitle.part2[state.lang],
      title3: (state) => state.coverTitle.part3[state.lang],
      searchHolder: (state) => state.searchHolder[state.lang],
      copy: (state) => state.footer.copy[state.lang],
      made: (state) => state.footer.made[state.lang],
      linkPhase: (state) => state.linkPhase[state.lang],
      //modal
      modal: (state) => state.modal,
      currentPark: (state) => state.currentPark,
      // foot
      uriEmilima: (state) => state.uriEmilima,
      // visita lima
      visitalimaIMG: (state) => state.visitaLima.img,
      visitaLimaTitle: (state) => state.visitaLima.title[state.lang],
      visitaLimaDescription: (state) =>
        state.visitaLima.description[state.lang],
      visitaLimaURL: (state) => state.visitaLima.url,
      visitaLimaURLPhrase: (state) => state.visitaLima.urlPhrase[state.lang],
      //anounces
      anounces: (state) => state.anounces,
      modalAnounces: (state) => state.modalAnounces,
      // text status
      commingText: (state) => state.statusSite.commingText[state.lang],
      maintanceText: (state) => state.statusSite.maintanceText[state.lang],
    }),
    isPublicView() {
      console.log("RouteName", this.$route.name);
      return this.publicViewNames.includes(this.$route.name);
    },
    getActive() {
      if (this.modal) {
        this.removeScrollHTML();
        return true;
      }
      this.addScrollHTML();
      return false;
    },
  },
  async created() {
    try {
      // Fetch
      this.$store.commit("activeLoader", true);
      /* await this.$store.dispatch("validateSubdomain");
      await this.$store.dispatch("getGlobalData");
      await this.$store.dispatch("getParksList");
      // await this.$store.dispatch("getParkByDomain") */
      await this.$store.dispatch("getAnounces");
      this.$store.commit("activeLoader", false);
      this.status = true;
    } catch (error) {
      this.$store.commit("activeLoader", false);
    }
  },
  mounted() {
    this.$store.dispatch("openModalAnounces");
  },
  methods: {
    ...mapActions({
      getParksByParent: "getParksByParent",
    }),
    async getParksByParentId(parent) {
      const list = await this.getParksByParent(parent);
      this.parkListByParent = list;
    },
    removeScrollHTML() {
      try {
        document.documentElement.style.overflow = "hidden";
        document.querySelector(".v-main__wrap").style.overflow = "hidden";
      } catch (error) {}
    },
    addScrollHTML() {
      try {
        document.documentElement.style.overflow = "auto";
        document.querySelector(".v-main__wrap").style.overflow = "auto";
      } catch (error) {}
    },
  },
};
</script>
