<template>
    <div :class="`lm--modal ${active ? 'active' : ''}`">
        <div class="lm--modal-scroll">
            <v-container >
                <slot name="modal-content"></slot>
            </v-container>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            active: Boolean
        }
    }
</script>

<style lang="scss" scoped>
.lm--modal {
    position: fixed;
    top: 80px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000000;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out .35s;
    overflow: hidden;
    z-index: 150;
    .lm--modal-scroll {
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.lm--modal.active {
    opacity: 1;
    visibility: visible;
}
.h-100 {
    max-height: 100%;
    height: 100vh;
    box-sizing: border-box;
    /* overflow-y: hidden; */
}
</style>