<template>
  <v-dialog
    max-width="650px"
    width="650px"
    v-model="dialog"
    persistent
    style="box-sizing: border-box"
  >
    <v-card
      max-width="650px"
      height="auto"
      class="
        p-0
        elevation-0
        d-flex
        flex-column
        card-anounces
        align-items-center
      "
      elevation="0"
      color="transparent"
      style="box-sizing: border-box"
    >
      <Slide
        v-if="options"
        class="slide-anounces small-arrows"
        :reinitiate="true"
        :selectorId="selectorId"
        :noArrows="false"
        :opts="options"
      >
        <template v-slot:btn-close>
          <div class="btn-close">
            <button
              class="readable"
              @click="$store.dispatch('closeModalAnounces')"
              type="button"
              :title="btnClose[lang]"
            >
              <i class="material-icons">close</i>
            </button>
          </div>
        </template>
        <template v-slot:slide-items>
          <div
            v-for="(item, idx) in anounces || []"
            :key="idx + 1120"
            class="swiper-slide anounce-item"
          >
            <img
              @click="openLink(item.URL)"
              class="img-anounce"
              draggable="false"
              :src="`${baseURL}/repositorio/${item.IMAGE}`"
              :alt="`${appName} ${item.TITULO}`"
            />
          </div>
        </template>
      </Slide>
      <div>
        <Slide
          class="slide-gallery-anounces"
          :reinitiate="false"
          selectorId="gallery-anounces"
          :noArrows="true"
          :opts="galleryOptions"
          @create="galleryCreated"
        >
          <template v-slot:slide-items>
            <div
              v-for="(item, idx) in anounces || []"
              :key="idx + 1120"
              class="swiper-slide"
            >
              <div class="mp-img-thumb">
                <img
                  draggable="false"
                  class="img-anounce"
                  :src="`${baseURL}/repositorio/${item.IMAGE}`"
                  :alt="`${appName} ${item.TITULO}`"
                />
              </div>
            </div>
          </template>
        </Slide>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Slide from "@/components/slides/Slide";
import { mapGetters } from 'vuex';
export default {
  props: {
    dialog: Boolean,
    anounces: Array,
    baseURL: String,
  },
  components: {
    Slide,
  },
  data() {
    return {
      swiperGallery: null,
      btnClose: {
        en: "Close popup",
        es: "Cerrar popup"
      },
      selectorId: "anounces-slide",
      options: null,
      galleryOptions: {
        spaceBetween: 2,
        slidesPerView: "auto",
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      },
      appName: process.env.VUE_APP_BASE_NAME
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage"
    })
  },
  methods: {
    galleryCreated(ev) {
      this.options = {
        lazy: true,
        speed: 400,
        spaceBetween: 0,
        grabCursor: true,
        autoplay: {
          autoplay: true,
          delay: 6000,
          disableOnInteraction: true,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        navigation: {
          nextEl: `#anounces-slide .swiper-button-next`,
          prevEl: `#anounces-slide .swiper-button-prev`,
        },
        thumbs: {
          swiper: ev,
        },
      };
    },
    openLink(link) {
      if (link != "#") {
        window.open(link);
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-dialog-anounces {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  overflow: hidden;
}
.slide-anounces {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  height: 75vh;
  max-height: 700px;
  overflow: hidden;
}
.swiper-slide.anounce-item {
  height: 100%;
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  /* padding: 1em; */
  box-sizing: border-box;
  /* background: #fff; */
  .img-anounce {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.slide-gallery-anounces {
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  padding: 10px;
  //max-width: 650px;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
    height: 100%;
    .swiper-slide {
      height: 100%;
      width: 60px;
      filter: grayscale(100%);
      max-width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .mp-img-thumb {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        img {
          cursor: pointer;
          object-fit: cover;
        }
        .img-anounce {
          max-height: 100%;
        }
      }
    }
    .swiper-slide-thumb-active {
      filter: grayscale(0);
      img {
        cursor: not-allowed !important;
        border: 3px solid #00d6a5
      }
    }
  }
}
</style>
