<template>
  <!-- Search -->
  <div class="lm--container-searcher container">
    <div class="lm--searcher boder-circle" :title="searchHolder">
      <input
        id="search-input"
        type="text"
        :placeholder="
          searchHolder ? searchHolder : 'Busca entre los centros de esparcimiento'
        "
        autocomplete="off"
      />
      <button disabled type="button">
        <i class="fas fa-search"></i>
      </button>
      <div class="lm--datalist">
        <button
          type="button"
          v-for="(item, idx) in results"
          :key="idx + 100"
          :to="`/${item.slug}`"
          class="lm--datalist-item"
          @click="openParkInfo(item)"
        >
          <span>{{ item.DESCRIPCION }}</span>
        </button>
      </div>
    </div>
  </div>
  <!-- End search -->
</template>

<script>
export default {
  props: {
    searchHolder: String,
  },
  data() {
    return {
      results: [],
    };
  },
  mounted() {
    const searcher = document.getElementById("search-input");
    searcher.addEventListener("focus", this.onFocusEv);
    searcher.addEventListener("blur", this.removeActive);
    searcher.addEventListener("keyup", this.handleKeyUp);
  },
  methods: {
    onFocusEv() {
      document.querySelector(".lm--searcher").classList.add("active");
    },
    removeActive() {
      document.querySelector(".lm--searcher").classList.remove("active");
    },
    activeResultsList() {
      document.querySelector(".lm--searcher").classList.add("results");
    },
    removeResultsList() {
      document.querySelector(".lm--searcher").classList.remove("results");
      this.results = [];
    },
    handleKeyUp(ev) {
      if (ev.target.value && ev.target.value.length > 0) {
        //busqueda
        this.searchResults(ev.target.value);
        if (this.results.length > 0) {
          this.onFocusEv();
          this.activeResultsList();
        } else {
          this.removeResultsList();
        }
      } else {
        this.removeResultsList();
      }
    },
    searchResults(keyword) {
      //find
      const list = [...this.$store.state.parkList];
      this.results = list.filter((it) => {
        const arr = it.DESCRIPCION.split(" "); //arr keywords on items
        let obj = null;
        arr.forEach((name) => {
          const arrKeyUp = keyword.split(" ");
          arrKeyUp.forEach((key) => {
            if (this.removeSpecialC(name).indexOf(this.removeSpecialC(key)) > -1) {
              obj = it;
            }
          });
        });
        if (obj) return it;
      });
    },
    removeSpecialC(str) {
      str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      str = str.toLowerCase();
      str = str.trim();
      return str;
    },
    openParkInfo(item) {
      this.$store.dispatch("changeCurrentPark", item);
      this.$store.dispatch("openModal");
    },
  },
};
</script>

<style lang="scss" scoped>
//Searcher
.lm--container-searcher {
  position: relative;
  width: 100%;
  //left: 50%;
  height: $searcherHeight;
  //top: calc(100% - 45px);
  background: #f4f4f4;
  border-radius: 5px;
  box-shadow: -1px 4px 74px -16px rgba(0, 0, 0, 0.3);
  display: block;
  //transform: translate(-50%, 0);
  padding: 1em 12px !important;
  z-index: 10;
  box-sizing: border-box;
  @media screen and (max-width: 599px) {
    border-radius: 5px 5px 0 0;
    //top: calc(100% - 90px);
    display: flex;
    align-items: center;
  }
}

.lm--searcher {
  margin: auto;
  padding: 0.55em 1em;
  display: flex;
  justify-content: space-between;
  background: #fff;
  //margin: 1em 0;
  transition: all ease-in-out 0.2s;
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  @media screen and (min-width: 1650px) {
    max-width: 550px;
  }
  @media screen and (min-width: 1900px) {
    max-width: 800px;
  }
  @media screen and (max-width: 850px) {
    padding: 0.5em 1em;
  }
  @media screen and (max-width: 650px) {
    padding: 0.5em 0.8em;
  }
  @media screen and (max-width: 599px) {
    margin: 1em auto;
    width: 80%;
    max-width: 80%;
  }
  @media screen and (max-width: 450px) {
    max-width: 88%;
    width: 88%;
    padding: 0.3em 1em;
  }
  @media screen and (max-width: 400px) {
    max-width: 95%;
    width: 95%;
    padding: 0.3em 1em;
  }
  @media screen and (max-width: 353px) {
    max-width: 100%;
    padding: 0.3em 1em;
  }
  &.boder-circle {
    border-radius: 20px;
  }
  input {
    width: 100%;
    padding: 0.6em 0;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
    @media screen and (max-width: 353px) {
      font-size: 12px;
    }
  }
  button {
    background: transparent;
    color: $color-tertiary;
    outline: none;
  }
}
.lm--searcher.active {
  box-shadow: 1px 1px 1px 3px rgba(26, 74, 132, 0.3);
}

.lm--datalist {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  height: auto;
  background: #fff;
  padding: 0.5em 0;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.2s;
  box-shadow: 1px 1px 1px 3px rgba(26, 74, 132, 0.3);
  display: flex;
  flex-flow: column nowrap;
  .lm--datalist-item {
    padding: 0.4em 1.5em;
    text-align: left;
    outline: none;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-weight: normal;
    text-decoration: none;
    transition: all ease-in-out 0.15s;
    &:hover {
      background: $color-tertiary;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
.lm--searcher.results.active {
  border-radius: 20px 20px 0 0;
  .lm--datalist {
    visibility: visible;
    opacity: 1;
  }
}

.lm--vertical-center {
  display: flex;
  align-items: center;
}
.lm--vertical-center:first-of-type {
  @media screen and (max-width: 599px) {
    order: 3;
  }
}
</style>
